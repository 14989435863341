<template>
    <div class="show-not-found background-not-supported">
        <div class="container-flex">
            <img :src="logoCaredokter" />
            <img :src="errorLaptop" />
            <template v-if="!isOnlySahabat">
                <label class="font-forbidden">Account not assigned</label>
                <label class="font-access"
                    >Akun Anda belum terdaftar ke grup manapun. Kontak Admin
                    untuk info lebih lanjut.</label
                >
                <button class="btn btn-primary-caredokter" @click="adminContact(false)">
                    Kontak Admin
                </button>
            </template>
            <template v-else>
                <label class="font-forbidden">Account not yet connected</label>
                <label class="font-access">
                    Akun Anda belum terhubung ke aplikasi ini. Kontak Admin
                    untuk info lebih lanjut.
                </label>
                <button class="btn btn-primary-caredokter" @click="adminContact(true)">
                    Kontak Admin
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import logOut from "@/services/logOut.service";
import laptopError from "@/assets/img/laptop-error.png";
import logoCaredokter from "@/assets/img/caredokter.png";
import CheckPermission from "@/services/checkPermission.service";
const checkPermission = new CheckPermission();

export default {
    data() {
        return {
            isOnlySahabat: false,
            errorLaptop: `${laptopError}`,
            logoCaredokter: `${logoCaredokter}`,
        };
    },
    mounted() {
        checkPermission.updatePermission().then(() => {
            const validPermission = checkPermission.isCanAccess(
                'BackOffice.View.Active.SAHABAT'
            );
            this.isOnlySahabat = validPermission;
        });
    },
    methods: {
        logout() {
            logOut.logout();
        },
        adminContact (isCondition) {
          let errorMessage = ''
          if(isCondition) {
            errorMessage = 'Anda tidak memiliki akses ke Backoffice'
          } else {
            errorMessage = 'Anda tidak memiliki akses ke Backoffice/Sahabat'
          }
          window.keycloak.loadUserInfo()
            .then(_resp => {
              this.getN8N(_resp.preferred_username,
              errorMessage,
               _resp.email,
               _resp.sub,
               _resp.tenant_id)
              this.logout()
            })
        },
        popupCenter ({url, title, w, h}) {
          // Fixes dual-screen position                             Most browsers      Firefox
          const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
          const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

          const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
          const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

          const systemZoom = width / window.screen.availWidth;
          const left = (width - w) / 2 / systemZoom + dualScreenLeft
          const top = (height - h) / 2 / systemZoom + dualScreenTop
          const newWindow = window.open(url, title, 
            `
            scrollbars=yes,
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}
            `
          )

          if (window.focus) newWindow.focus();
      },
        getN8N (username, message, email, sub, tenant) {
          const origin = window.location.origin
          const url = `https://n8n.mandayamedical.group/webhook/KontakAdmin?un=${username}&msg=${message}&em=${email}&sub=${sub}&tenant=${tenant}&origin=${origin}`
          this.popupCenter({url, title: '_blank', w: 600, h: 200});  
        }
    },
};
</script>
